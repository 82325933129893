.SavedAndFollowingCon1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--app-white, #fff);
}
.SavedAndFollowingCon2 {
  display: flex;
  /* padding: 12px 0px; */
  flex-direction: column;
  align-items: center;
  /* gap: 32px; */
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;
}
.SavedAndFollowingCon3 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-bottom: 0.5px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  z-index: 0;
}

.SavedAndFollowingCon4 {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.SavedAndFollowingCon5 {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
}
.SavedAndFollowingText1 {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SavedAndFollowingCon6 {
  display: flex;
  width: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.SavedAndFollowingCon7 {
  display: flex;
  padding: 0px 6px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}
.SavedAndFollowingCon8 {
  display: flex;
  padding: 3px 6px;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25) inset;
}
.SavedAndFollowingText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.SavedAndFollowingCon9 {
  display: flex;
  padding: 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.SavedAndFollowingCon9B {
  display: flex;
  /* padding: 24px 12px; */
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  overflow: hidden;
  overflow-y: scroll;
}
.SavedAndFollowingConB10 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.SavedAndFollowingConB11 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--app-green, #3a7d44);
}
.SavedAndFollowingTextB10 {
  flex: 1 0 0;
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
