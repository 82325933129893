.SavedAndFollowingCon1 {
  background: var(--app-white, #fff);
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.SavedAndFollowingCon2 {
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.SavedAndFollowingCon3 {
  border-bottom: .5px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
  z-index: 0;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  display: flex;
  box-shadow: 0 4px 12px #00000040;
}

.SavedAndFollowingCon4 {
  align-self: stretch;
  align-items: center;
  gap: 12px;
  display: flex;
}

.SavedAndFollowingCon5 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  flex: 1 0 0;
  align-items: center;
  gap: 12px;
  padding: 6px;
  display: flex;
  box-shadow: 0 1px 4px 2px #00000040;
}

.SavedAndFollowingText1 {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SavedAndFollowingCon6 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 12px;
  display: flex;
}

.SavedAndFollowingCon7 {
  flex-wrap: wrap;
  align-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 0 6px;
  display: flex;
}

.SavedAndFollowingCon8 {
  background: var(--app-white, #fff);
  border-radius: 3px;
  align-items: center;
  gap: 10px;
  padding: 3px 6px;
  display: flex;
  box-shadow: inset 0 0 2px 1px #00000040;
}

.SavedAndFollowingText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SavedAndFollowingCon9 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 12px;
  display: flex;
}

.SavedAndFollowingCon9B {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  height: 100%;
  display: flex;
  overflow: hidden scroll;
}

.SavedAndFollowingConB10 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 12px;
  display: flex;
}

.SavedAndFollowingConB11 {
  border: 1px solid var(--app-green, #3a7d44);
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
}

.SavedAndFollowingTextB10 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/*# sourceMappingURL=index.b94ffeb6.css.map */
